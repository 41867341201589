/* global
 autosize
 bsCustomFileInput
 checkRedirect
 initHtmlTextarea
 fetch_reservation_data
 $x
*/


// #############################################################################
// GLOBAL VARS

const $body = $("body");

// #############################################################################
// BETTER FOCUS

const $betterFocus = new $x.BetterFocus();
$betterFocus.init();

// #############################################################################
// TOOLTIP

$("[data-toggle=tooltip]").tooltip();

// #############################################################################
// FORM: MULTIPLE SELECT ITEMS

$x.initMultipleSelectItems = () => {
  const $inputs = document.querySelectorAll("[data-multiple-select-item]");

  $inputs.forEach(function ($input) {
    if ($input.$multipleSelectItem) {
      return;
    }

    $input.$multipleSelectItem = new $x.MultipleSelectItems($input);
    $input.$multipleSelectItem.init();
  });
};

// #############################################################################
// Ajax upload

$x.AjaxUpload = class {
  constructor ($element, $options) {
    const $defaults = {
      addExtraData: function ($upload, $form_data) {
        return $form_data;
      },
      onUploadCompleted: function ($upload, $data) {
      },
      singelProgress: true,
    };

    this.$settings = { ...$defaults, ...$options };

    this.$el = $($element);
    this.$input = $("input[type=file]", this.$el);
    this.$button = $("button", this.$el);
    this.$progress_items = $("[data-progress-items]", this.$el);
    this.filesPath = $(`#${this.$input.attr("id")}_path`);
    this.filesCount = $(`#${this.$input.attr("id")}_count`);
    this.filesSize = 0;
    this.currentLoaded = 0;
    this.errorCount = 0;
  }

  init () {
    const _this = this;

    this.$input.on("change", function () {
      _this.$files = this.files;
      _this.filesSize = 0;
      _this.currentLoaded = 0;

      _this._initProgressBar();
      _this._updateButton();
    });

    this.$button.on("click", function () {
      if (_this.$files.length > 0) {
        _this.$button.addClass("disabled");
        _this.$input.attr("disabled", "disabled");
        const identifier = _this.$input.data("ajax-upload-identifier");
        _this.filesPath.val(identifier);
        _this.filesCount.val(_this.$files.length.toString());
        const ajaxUrl = _this.$el.data("ajax-upload");
        _this.errorCount = 0;

        $.ajax({
          url: `${ajaxUrl}?identifier=${identifier}`,
          type: "GET",
          success: function (data) {
            $.each(_this.$files, function (index) {
              let $form_data = new FormData();
              const $file = _this.$files[index];

              $form_data = _this.$settings.addExtraData(_this.$el, $form_data);
              $form_data.append("file", $file);
              $form_data.append("identifier", identifier);

              $.ajax({
                contentType: false,
                data: $form_data,
                processData: false,
                type: "POST",
                url: ajaxUrl,
                success: function ($data) {
                  _this.$button.addClass("disabled");
                  _this.$input.removeAttr("disabled");

                  _this._clearInput();

                  _this.$settings.onUploadCompleted(_this.$el, $data);
                },
                error: function (request, status, error) {
                  _this.errorCount++;
                  return _this._updateProgressBar($file);
                },

                xhr: function () {
                  return _this._updateProgressBar($file);
                },
              });
            });
          },
        });
      }

      return false;
    });
  }

  reset () {
    const _this = this;

    _this._clearInput();
    _this.$progress_items.attr("hidden", "hidden");
  }

  _clearInput () {
    const _this = this;

    bsCustomFileInput.destroy();
    _this.$input.val("");
    bsCustomFileInput.init();
  }

  _updateButton () {
    const _this = this;

    if (_this.$files.length > 0 && !_this.$input.hasClass("is-invalid")) {
      _this.$button.removeClass("disabled");
    } else {
      _this.$button.addClass("disabled");
    }
  }

  _initProgressBar () {
    const _this = this;
    _this.$progress_items.removeClass("text-danger");

    if (!_this.progress_item) {
      const $progress_item = $("[data-progress-item]", _this.$progress_items);
      _this.progress_item = $progress_item.parent().html();
    }

    _this.$progress_items.empty();

    if (_this.$files.length > 0 && !this.$input.hasClass("is-invalid")) {
      _this.$progress_items.removeAttr("hidden");

      if (_this.$settings.singelProgress) {
        this._initOneProgressBar();
      } else {
        this._initMultiProgressBar();
      }
    } else {
      _this.$progress_items.attr("hidden", "hidden");
    }
  }

  _initOneProgressBar () {
    const _this = this;

    $.each(_this.$files, function (index) {
      const $file = _this.$files[index];
      _this.filesSize += $file.size;
    });

    const item = $(_this.progress_item.replace(
      "%filename", `${_this.$files.length} files`
    ).replace(
      "%filesize", $x.formatFileSize(_this.filesSize)
    ).replace(
      "data-progress-item=\"\"", "data-progress-item=all_files"
    ));
    _this.$progress_items.append(item);
    $("[data-file-size]").data("file-size", _this.filesSize);
  }

  _initMultiProgressBar () {
    const _this = this;

    $.each(_this.$files, function (index) {
      const $file = _this.$files[index];

      const $item = $(_this.progress_item.replace(
        "%filename", $file.name
      ).replace(
        "%filesize", $x.formatFileSize($file.size)
      ).replace(
        "data-progress-item=\"\"", "data-progress-item=\"" + $file.name + "\""
      ));

      _this.$progress_items.append($item);
    });
  }

  toggleDisplay($item, show) {
    if (show) {
      $item.removeClass("d-none").addClass("d-block");
    } else {
      $item.removeClass("d-block").addClass("d-none");
    }
  }

  _updateProgressBar ($file) {
    const _this = this;

    let $item = $("[data-progress-item=\"" + $file.name + "\"]", _this.$el);
    if (_this.$settings.singelProgress) {
      $item = $("[data-progress-item='all_files']", _this.$el);
    }
    const $xhr = $.ajaxSettings.xhr();
    const $progress_bar = $("[data-progress-bar]", $item);
    const $file_size = $("[data-file-size]", $item);
    const $current_file_size = $("[data-current-file-size]", $item);
    const $total_file_size = $("[data-total-file-size]", $item);
    const $cancel_upload = $("[data-cancel-upload]", $item);
    const $upload_finished = $("[data-upload-finished]", $item);
    let max = _this.filesSize;

    if (_this.errorCount > 0) {
      $progress_bar.addClass("bg-danger");
      _this.$progress_items.addClass("text-danger");
      _this.toggleDisplay($cancel_upload, true);
      _this.toggleDisplay($upload_finished, false);
      _this._clearInput();
    } else {
      $progress_bar.removeClass("bg-danger");
      _this.toggleDisplay($cancel_upload, false);
      _this.toggleDisplay($upload_finished, true);
    }

    $xhr.upload.addEventListener("progress", function (event) {
      if (event.lengthComputable) {
        if (_this.$settings.singelProgress) {
          _this.currentLoaded += event.loaded;
        } else {
          max = event.total;
          _this.currentLoaded = event.loaded;
        }
        const percentage = (_this.currentLoaded * 100) / max;

        $progress_bar.css("width", percentage + "%");
        $progress_bar.attr("aria-valuenow", percentage);
        $current_file_size.html($x.formatFileSize(_this.currentLoaded));

        if ($total_file_size.html() === "") {
          $file_size.prop("hidden", false);
          $total_file_size.html($x.formatFileSize(max));
        }
      }
    }, false);

    $xhr.upload.addEventListener("loadstart", function () {
      $total_file_size.empty();
      $cancel_upload.removeClass("invisible");
    });

    $xhr.upload.addEventListener("load", function () {
      _this.toggleDisplay($cancel_upload, false);
      _this.toggleDisplay($upload_finished, true);
    });

    $xhr.upload.addEventListener("abort", function () {
      const $items = $("[data-progress-item]", _this.$progress_items).not($item);

      $item.remove();

      if ($items.length === 0) {
        _this.$input.removeAttr("disabled");
        _this.reset();
      }
    });

    $cancel_upload.on("click", function () {
      $xhr.abort();
      return false;
    });

    return $xhr;
  }
};

// #############################################################################
// FORM

$x.initFormDefaults = function ($parent = $body) {
  // File
  // TODO: Selber schreiben!
  bsCustomFileInput.init();

  // Autosize
  autosize($("textarea", $parent));

  // HTML CKEditor
  initHtmlTextarea($parent);

  // Range
  $("[type=range]", $parent).formRange();

  // Ajax upload
  const $ajaxUpload = new $x.AjaxUpload($("[data-ajax-upload]", $parent), {
    onUploadCompleted: function ($upload, $data) {
      $x.replaceHtml($data);
    },
  });

  $ajaxUpload.init();

  // File tree
  $("[data-file-tree]", $parent).formFileTree();

  // Form set
  $("[data-form-set]", $parent).formSet({
    onAdd: function (formset, $items, $newItem, count) {
      if (formset.$el.data("form-set") === "columns") {
        columnFormsetReOrder($items);
      }
    },
    onDelete: function (formset, $items, $deletedItem) {
      if (formset.$el.data("form-set") === "columns") {
        columnFormsetReOrder($items);
      }
    },
    onInit: function (formset, $items) {
      if (formset.$el.data("form-set") === "columns") {
        columnFormsetReOrder($items);
      }
    },
  });

  $x.initMultipleSelectItems();

  return {
    ajaxUpload: $ajaxUpload,
  };
};

const $formDefaults = $x.initFormDefaults();

// Validation

$("[data-form]").formValidation({
  beforeSubmit: function () {
    $formDefaults.ajaxUpload.reset();
  },
  afterSubmit: function ($xhr, $form, $data) {
    if ($data.submit === "success") {
      if ($form[0].id === "id_form_ipspin_reservation") {
        fetch_reservation_data($form.data("fetch-url"), $data.data);
      }
      if ($data.redirect) {
        checkRedirect($data);
      } else {
        $x.replaceHtml($data);

        if ($data.toaster) {
          $body.toaster("updateToaster", $data.toaster);
        }
      }
    }
  },
});

function destroy_form_validation ($form) {
  if ($form.length) {
    $form.formValidation("destroy");
    $form.unbind();
  }
}

// Wizard

$("[data-form-wizard]").formWizard();


// #############################################################################
// DATA TABLE

function datatable_data_to_json (data_dict) {
  const filter_data_dict = {};
  function generate_filter_data (data_dict, prefix) {
    prefix = typeof prefix !== "undefined" ? prefix : false;
    $.each(data_dict, function (key, val) {
      if (prefix) { key = `${prefix}[${key}]`; }
      if (Array.isArray(val)) {
        generate_filter_data(val, key);
      } else if (val instanceof Object) {
        generate_filter_data(val, key);
      } else {
        filter_data_dict[key] = String(val);
      }
    });
  }
  generate_filter_data(data_dict);
  return filter_data_dict;
}

let $dataTables = null;

function init_x_datatables() {
  $dataTables = document.querySelectorAll("[data-table]");

  $dataTables.forEach(function ($item) {
    $item.$datatable = new $x.DataTables($item, {
      api: function ($table, $api) {
        // API: https://datatables.net/reference/api/

        const $input = $table.querySelector("[data-multiple-select-item]");

        $api.on("draw", function () {
          $("[data-toggle=tooltip]", $table).tooltip();

          if ($input) {
            $input.$multipleSelectItem.reset();
          }
        });
        $body.on("submit", "form.x-datatable-export-form", function (e) {
          const filter_data_dict = datatable_data_to_json($api.columns().ajax.params());
          const export_list_filter_data = $("input[name='export_list_filter_data']");

          if (!export_list_filter_data.length) {
            $("<input />").attr("type", "hidden")
              .attr("name", "export_list_filter_data")
              .attr("value", JSON.stringify(filter_data_dict))
              .appendTo($(this));
          }
          export_list_filter_data.val(JSON.stringify(filter_data_dict));
        });
      },
      customizeCSV: function (csv) {
        // For customization read https://datatables.net/reference/button/csv

        return csv;
      },
      rowGroupStartRender: function ($table, $rows, html) {
        return html;
      },
    });
  });
}

init_x_datatables();

// #############################################################################
// FULL CALENDAR

const $calendars = document.querySelectorAll("[data-calendar]");

$calendars.forEach(function ($item) {
  $item.$calendar = new $x.FullCalendar($item);
});

// #############################################################################
// CHART JS

const $charts = document.querySelectorAll("[data-chartjs]");

$charts.forEach(function ($element) {
  const $chartJS = new $x.ChartJS($element);
  $chartJS.init();
});

// #############################################################################
// MODAL

$x.onModalOpenDefault = function ($modal) {
  $("[autofocus]", $modal).focus();
  $("[data-toggle=tooltip]", $modal).tooltip();
  dragAndDrop();

  const $djangoSelect2 = $(".django-select2");
  if ($djangoSelect2.length) {
    // Init Select2 Fields in the modal dialog, only if there is any django-select2 field.
    $djangoSelect2.djangoSelect2({
      dropdownParent: $("#modal"),
    });
  }

  const $formDefaults = $x.initFormDefaults($modal);

  // Validation

  $("[data-form]", $modal).formValidation({
    beforeSubmit: function () {
      $formDefaults.ajaxUpload.reset();
      $(".test-verification-result").html("");
    },
    afterSubmit: function ($xhr, $form, $data) {
      if ($data.submit === "success") {
        $modal.modal("hide");

        if ($data.redirect) {
          checkRedirect($data);
        } else {
          $x.replaceHtml($data);

          if ($data.toaster) {
            $body.toaster("updateToaster", $data.toaster);
          }

          if ($data.async_process) {
            update_data_of_running_process();
          }

          $dataTables.forEach(function ($item) {
            $item.$datatable.reload();
          });

          $calendars.forEach(function ($item) {
            $item.$calendar.$api.refetchEvents();
          });
        }
      } else if ($data.submit === "replace") {
        $x.replaceHtml($data);
      }
      blank_redirect($data);
    },
  });

  // Wizard

  $("[data-form-wizard]", $modal).formWizard();
};

function blank_redirect ($data) {
  if ($data.blank_redirect) {
    window.open($data.blank_redirect, "_blank");
  }
}

$x.beforeModalOpen = function ($modal, $data) {
  let json_data = {};
  try {
    json_data = JSON.parse($data);
  } catch (e) {
    json_data = {};
  }
  console.log(json_data);
  if (json_data.toaster) {
    $body.toaster("updateToaster", json_data.toaster);
  }
  if (json_data.blank_redirect) {
    $("#modal_wrapper").empty();
    window.open(json_data.blank_redirect, "_blank");
  }
  $(".multi-select").multiSelect({
    cssClass: "w-100",
    selectableHeader: "<input type='text' class='search-input select2-search__field w-100' autocomplete='off' placeholder='Filter'>",
    selectionHeader: "<input type='text' class='search-input select2-search__field w-100' autocomplete='off' placeholder='Filter'>",
    afterInit: function(ms) {
      const that = this;
      const $selectableSearch = that.$selectableUl.prev();
      const $selectionSearch = that.$selectionUl.prev();
      const selectableSearchString = "#" + that.$container.attr("id") + " .ms-elem-selectable:not(.ms-selected)";
      const selectionSearchString = "#" + that.$container.attr("id") + " .ms-elem-selection.ms-selected";

      that.qs1 = $selectableSearch.quicksearch(selectableSearchString).on("keydown", function(e) {
        if (e.which === 40) {
          that.$selectableUl.focus();
          return false;
        }
      });

      that.qs2 = $selectionSearch.quicksearch(selectionSearchString)
        .on("keydown", function(e) {
          if (e.which === 40) {
            that.$selectionUl.focus();
            return false;
          }
        });
    },
    afterSelect: function() {
      this.qs1.cache();
      this.qs2.cache();
    },
    afterDeselect: function() {
      this.qs1.cache();
      this.qs2.cache();
    },
  });
};

$x.delegateEvent.on(document, "click", "[data-modal-link]", function (e) {
  e.preventDefault();

  $x.modal.open(this.href, {
    beforeModalOpen: $x.beforeModalOpen,
    onModalOpen: $x.onModalOpenDefault,
  });
});

// #############################################################################
// DOWNLOAD BLOB

$x.delegateEvent.on(document, "click", "[data-download]", function (e) {
  e.preventDefault();

  const $downloadBlob = new $x.DownloadBlob({
    onDownloadStarted: function ($data) {
      $body.toaster("updateToaster", $data.toaster);

      $dataTables.forEach(function ($item) {
        $item.$datatable.reload();
      });
    },
  });

  $downloadBlob.download(this.href);
});

// #############################################################################
// CLIPBOARD

$body.clipBoard({
  selector: "[data-clipboard]",
});

// #############################################################################
// TOASTER

$body.toaster({
  selector: "[data-toaster]",
});

// #############################################################################
// AUTO UPDATE HTML CONTENT

// TODO: Demo erstellen

$body.autoUpdateHtmlContent({
  selector: "[data-update-html-content]",
});

const progress_table = $("[data-progess-url]");
let progress_table_interval = null;
if (progress_table.length) {
  update_data_of_running_process();
}

function update_data_of_running_process () {
  function ajax_call () {
    $.ajax({
      url: progress_table.data("progess-url"),
      type: "GET",
      success: function (data) {
        if (data.status === "continue") {
          $.each(data.items, function (key, value) {
            $(`#row-${key}`)
              .css("background-image", `linear-gradient(to right, #2dff0145 ${value}%, white ${value}%)`)
              .find(".icon-table.dtr-control").html($(`<span class="row-counter">${value}%</span>`));
          });
        } else if (data.status === "finish") {
          $("tr[id^='row-']").css("background-image", "none").find(".row-counter").remove();
          $dataTables.forEach(function ($item) {
            $item.$datatable.reload();
          });
          clearInterval(progress_table_interval);
        }
        $x.replaceHtml(data);
      },
    });
  }
  progress_table_interval = setInterval(ajax_call, 1000);
}

$body.on("shown.bs.tab", ".scheduler-tab-btn", function (e) {
  const prev_target = $(e.relatedTarget.dataset.target);
  prev_target.find("input[type='radio']").prop("checked", false);
});


// #############################################################################
// WORKFLOW STEPS
const stepTypeSelector = document.getElementById("step_creation_type_selector");
const stepActionLink = document.getElementById("step_action_link_create");

// Add a change event listener
if (stepTypeSelector) {
  stepTypeSelector.addEventListener("change", function () {
    const selectedOption = stepTypeSelector.options[stepTypeSelector.selectedIndex];
    stepActionLink.href = selectedOption.getAttribute("data-step-create-url");
    stepActionLink.setAttribute("data-modal-link", "");
  });
}

// #############################################################################
// WORKFLOW RUN STEPS
let form_data_dict = {};

function collect_form_data() {
  form_data_dict = {};
  $(":input:not(button):not([name=csrfmiddlewaretoken]), textarea, select").each(function () {
    const $elm = $(this);
    let old_val = $elm.val();
    if ($elm.is(":checkbox")) {
      old_val = $elm.prop("checked");
    }
    form_data_dict[this.id] = old_val;
  });
}

function is_form_data_changed () {
  let has_changed = false;
  $.each(form_data_dict, function (id, old_val) {
    const $elm = $(`#${id}`);
    let new_val = $elm.val();
    if ($elm.is(":checkbox")) {
      new_val = $elm.prop("checked");
    }
    if ($elm.length && new_val !== old_val) {
      has_changed = true;
    }
  });
  return has_changed;
}

function confirm_change_form() {
  if (is_form_data_changed()) {
    const r = confirm("Changes are not saved - do you really want to ignore the changes?");
    if (!r) {
      return false;
    }
    collect_form_data();
    return true;
  }
  collect_form_data();
  return true;
}

function init_form_validation_for_steps () {
  $("[data-form]").formValidation({
    beforeSubmit: function () {
      $formDefaults.ajaxUpload.reset();
    },
    afterSubmit: function ($xhr, $form, $data) {
      if ($data.submit === "success") {
        if ($data.redirect) {
          checkRedirect($data);
        } else {
          $x.replaceHtml($data);

          if ($data.toaster) {
            $body.toaster("updateToaster", $data.toaster);
          }
          if ($data.resetFormValidation) {
            destroy_form_validation($form);
            init_form_validation_for_steps();
          }
          collect_form_data();
        }
      }
    },
  });
  $("[data-form-set]", $body).formSet();
}


// ##############################################################
// ##############################################################
// ##############################################################
// ##############################################################

function dragAndDrop () {
  const sortableElms = $(".drag-and-drop");
  sortableElms
    .parents("[data-form-set-item]")
    .css("cursor", "move");

  sortableElms.parents("[data-form-set-body]").sortable({
    update: function (event, ui) {
      columnFormsetReOrder(event.target.children);
    },
  });
}

function columnFormsetReOrder ($items) {
  console.log("test");
  $.each($items, function (index, item) {
    $(item).find(".position-input").val(index + 1);
  });
}

// #################################################################
// Image meta fields
$body.on("change", ".image-type-field", function () {
  const item = $(this);
  const meta_data_container = item.parent().closest(".row.sm-gutters").find(".image-meta-data-container");
  if (String(item.val()) === String(item.data("image_type"))) {
    meta_data_container.removeClass("d-none");
  } else {
    meta_data_container.addClass("d-none");
  }
});

// #################################################################
// periodic-task meta fields
$body.on("change", ".periodic-task-type-field", function () {
  const item = $(this);
  const meta_data_container = item.parent().closest(".row.sm-gutters").find(".periodic-task-informer-container");
  const row_fields = item.parent().closest(".row.sm-gutters").find(".informer-row");
  if (String(item.val()) === String(item.data("task_type"))) {
    meta_data_container.removeClass("d-none");
    row_fields.removeClass("col-sm-6").addClass("col-sm-4");
  } else {
    meta_data_container.addClass("d-none");
    row_fields.removeClass("col-sm-4").addClass("col-sm-6");
  }
});

// #################################################################
// LoginAs listener
$("body").on("click", ".x-loginas-link", function(event) {
  event.preventDefault();
  $(this).closest(".x-login-as-container").find(".x-loginas-form").submit();
});
